<template>
  <v-container
    fluid
    class="d-flex flex-column flex-nowrap pa-0"
    style="height: 100%; width: 100%;"
  >
    <v-card
      class="pa-2 secondary"
      tile
      dark
    >
      <v-row
        class="shrink"
        no-gutters
      >
        <v-col
          cols="6"
        >
          <v-select
            v-model="selected"
            :items="reports"
            item-text="name"
            item-value="value"
            label="Tipo"
            hide-details
          ></v-select>
        </v-col>
        <v-col
          cols="6"
          class="text-right align-self-center"
        >
          <v-btn
            class="primary"
            @click="openFilters"
            tile
            large
          >
            <v-icon>mdi-filter</v-icon>Filtrar
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-row
      class="grow"
      no-gutters
      v-if="!loading"
    >
      <v-col
        cols="12"
      >
        <incidents-report
          v-if="selected === 'incidents'"
          ref="incidents"
          :groups="groups"
        >
        </incidents-report>
        <heat-map-report
          v-if="selected === 'heat-map'"
          ref="heat-map"
          :groups="groups"
        >
        </heat-map-report>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from '@/util/api.service'

export default {
  components: {
    'incidents-report': () => import('@/components/reports/IncidentsReport.vue'),
    'heat-map-report': () => import('@/components/reports/IncidentsHeatMapReport.vue'),
  },
  data() {
    return {
      loading: true,
      filters: {},
      selected: 'incidents',
      devices: [],
      groups: [],
      reports: [{
        name: 'Incidentes por fecha',
        value: 'incidents'
      }, {
        name: 'Mapa de calor',
        value: 'heat-map'
      }]
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      const groupsPromise = ApiService({
        url: '/groups',
        method: 'get',
      })
      Promise.all([
        groupsPromise,
      ]).then(values => {
        this.devices = values[0]
        // eslint-disable-next-line
        let grupos = []
        if (values && values[1] && Array.isArray(values[1])) {
          values[1].forEach(item => {
            if (item.branch === 'devices') {
              grupos.push(item)
            }
          });
        }
        this.groups = grupos
        this.loading = false
      })
    },
    openFilters() {
      if (this.$refs[this.selected] && this.$refs[this.selected].filtrar) {
        this.$refs[this.selected].filtrar()
      }
    }
  }
}
</script>
